import React from 'react';
import { Button } from 'primereact/button';

const HeroSection = React.forwardRef(({ onStayInformedClick }, ref) => {
  return (
    <div ref={ref} className="hero-section" style={styles.container}>
      <div className="hero-overlay" style={styles.overlay}></div>
      <video autoPlay muted loop style={styles.video}>
        <source src={`${process.env.PUBLIC_URL}/hero-video.mp4`} type="video/mp4" />
      </video>
      
      <div className="flex flex-column hero-content slide-up" style={styles.content}>
        <h1 className="text-center text-white mb-3" style={styles.title}>
          Revolutionize Your Clinical Trials
        </h1>
        <h2 className="text-center text-white mb-5" style={styles.subtitle}>
          Coming Soon
        </h2>
        
        <div className="flex flex-column md:flex-row justify-content-center align-items-center gap-3">
          <Button 
            label="Get Started" 
            className="p-button-rounded p-button-lg"
            style={styles.primaryButton}
            onClick={onStayInformedClick}
          />
          <Button
            label="Compliance"
            icon="pi pi-shield"
            className="p-button-rounded p-button-lg"
            style={styles.primaryButton}
            onClick={() => window.open('https://compliance.phennx.io', '_blank')}
          />
        </div>
        
        <div className="mt-6 flex flex-column align-items-center">
          <Button
            label="Follow us on LinkedIn"
            icon="pi pi-linkedin"
            className="p-button-text"
            onClick={() => window.open('https://www.linkedin.com/company/phennx', '_blank')}
            style={styles.socialButton}
          />
        </div>
      </div>
    </div>
  );
});

const styles = {
  container: {
    position: 'relative',
    width: '100%',
    height: '100vh',
    overflow: 'hidden'
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(135deg, rgba(26, 26, 26, 0.9), rgba(51, 51, 51, 0.85))',
    zIndex: 1
  },
  video: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  content: {
    position: 'relative',
    zIndex: 2,
    padding: '0 1rem',
    height: '100vh',
    justifyContent: 'center'
  },
  title: {
    fontSize: 'clamp(2.5rem, 5vw, 4rem)',
    fontWeight: 'bold',
    textShadow: '0 2px 4px rgba(0,0,0,0.3)'
  },
  subtitle: {
    fontSize: 'clamp(1.2rem, 2vw, 1.5rem)',
    fontWeight: '300',
    maxWidth: '800px',
    margin: '0 auto',
    textShadow: '0 2px 4px rgba(0,0,0,0.3)'
  },
  primaryButton: {
    background: 'linear-gradient(135deg, #ff8c00, #ffd700)',
    border: 'none',
    padding: '1rem 2rem',
    fontSize: '1.1rem',
    color: '#1a1a1a',
    fontWeight: 'bold'
  },
  secondaryButton: {
    borderColor: 'white',
    color: 'white',
    padding: '1rem 2rem',
    fontSize: '1.1rem'
  },
  socialButton: {
    color: 'white',
    fontSize: '1rem'
  }
};

export default HeroSection;
