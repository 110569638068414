import React, { useState, useEffect } from 'react';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { Sidebar } from 'primereact/sidebar';

const NavBar = ({ homeClick }) => {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const startContent = (
    <div
      className="flex align-items-center gap-2 cursor-pointer"
      onClick={homeClick}
    >
      <Image src="favicon.png" width="48" height="48" />
      <h1 className="text-white m-0 text-xl">phennx.io</h1>
    </div>
  );

  const endContent = (
    <div className="flex align-items-center gap-3">
      <div className="hidden md:flex gap-3">
        <Button label="Products" className="p-button-text p-button-rounded" 
          style={{ color: 'white', fontWeight: 500 }} />
        <Button label="Solutions" className="p-button-text p-button-rounded" 
          style={{ color: 'white', fontWeight: 500 }} />
        <Button label="About" className="p-button-text p-button-rounded" 
          style={{ color: 'white', fontWeight: 500 }} />
        <Button label="Contact" className="p-button-rounded" 
          style={{ color: 'white', fontWeight: 500 }} />
      </div>
      <Button 
        icon="pi pi-bars" 
        className="md:hidden p-button-rounded p-button-text"
        onClick={() => setSidebarVisible(true)}
        style={{ color: 'white' }}
      />
    </div>
  );

  return (
    <>
      <div className={`navbar ${isScrolled ? 'backdrop-blur' : ''}`}>
        <Toolbar
          start={startContent}
          end={endContent}
          style={{
            borderRadius: '50px',
            transition: 'all 0.3s ease',
            background: isScrolled 
              ? 'linear-gradient(135deg, rgba(26, 26, 26, 0.95), rgba(51, 51, 51, 0.95))'
              : 'linear-gradient(135deg, rgba(26, 26, 26, 0.8), rgba(51, 51, 51, 0.8))'
          }}
        />
      </div>

      <Sidebar
        visible={sidebarVisible}
        onHide={() => setSidebarVisible(false)}
        position="right"
        className="w-full sm:w-20rem"
      >
        <div className="flex flex-column gap-3">
          <Button label="Products" className="p-button-text w-full" style={{ color: 'white', fontWeight: 500 }} />
          <Button label="Solutions" className="p-button-text w-full" style={{ color: 'white', fontWeight: 500 }} />
          <Button label="About" className="p-button-text w-full" style={{ color: 'white', fontWeight: 500 }} />
          <Button label="Contact" className="p-button-text w-full" style={{ color: 'white', fontWeight: 500 }} />
        </div>
      </Sidebar>
    </>
  );
};

export default NavBar;
